	.card-img-top {
    width: 100%;
    height: 10vw;
    object-fit: cover;
		object-position: top;
		border-bottom:1px solid #ddd;
	}

	.card-footer {
		background: unset !important;
	}

	.card {
		cursor: pointer;
	}